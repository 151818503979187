import { combineReducers, createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { responsiveStateReducer } from 'redux-responsive'
import { createResponsiveStoreEnhancer, createResponsiveStateReducer } from 'redux-responsive'
import thunk from 'redux-thunk';

const initialState = {
  menuLayerVisible: false,
  bookingLayerVisible: false,

};


const formSuccess = (state = { display: false }, action) => {
  switch (action.type) {

    case `CONTACT_FORM_SUCCESS`:
    case `BOOKING_FORM_SUCCESS`:
      return Object.assign({}, state, { display: true });
    case `DISMISS_FORM_SUCCESS`:
      return Object.assign({}, state, { display: false });


    default:
      return state;

  }
}

const contactForm = (state = { promotions: true }, action) => {
  switch (action.type) {

    case `UPDATE_CONTACT_FORM_DATA`:
      return Object.assign({}, state, action.formObject);

    case `TOGGLE_CONTACT_PROMOTIONS`:
      return Object.assign({}, state, { promotions: !state.promotions });



    default:
      return state;

  }
}

const bookingForm = (state = { promotions: true }, action) => {
  switch (action.type) {

    case `UPDATE_FORM_DATA`:
      return Object.assign({}, state, action.formObject);

    case `TOGGLE_BOOKING_PROMOTIONS`:
      return Object.assign({}, state, { promotions: !state.promotions });



    default:
      return state;

  }
}

const main = (state = initialState, action) => {

  switch (action.type) {
    case `WINDOW_SCROLL_TOP`:
      window.scrollTo(0, 0)
      return state;
    case `TOGGLE_MENU_LAYER`:
      return Object.assign({}, state, {
        menuLayerVisible: !state.menuLayerVisible
      });

    case `SHOW_BOOKING_LAYER`:
      return Object.assign({}, state, {
        bookingLayerVisible: true
      });

    case `DISMISS_BOOKING_LAYER`:
      return Object.assign({}, state, {
        bookingLayerVisible: false
      });
    default:
      return state;

  }


};


const reducers = combineReducers({
  main,
  formSuccess,
  bookingForm,
  contactForm,
  // browser: responsiveStateReducer,
  browser: createResponsiveStateReducer(null, { initialMediaType: 'small' })
})


const createStore = () => {

  let enhancers;

  if (process.env.NODE_ENV === 'development') {
    enhancers = compose(
      createResponsiveStoreEnhancer({ calculateInitialState: false }),
      applyMiddleware(thunk),
      // composeWithDevTools()
    )
  } else {
    enhancers = compose(
      createResponsiveStoreEnhancer({ calculateInitialState: false }),
      applyMiddleware(thunk),
      // composeWithDevTools()
    )
  }

  return reduxCreateStore(reducers, enhancers)

};
export default createStore;
